import * as React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { Layout } from '../../components/layout';
import { Seo } from '../../components/seo';
import * as Styles from '../../styles/pages/_login.module.scss';
import * as CommonStyles from '../../styles/global/_common.module.scss';
import { BreadCrumb } from '../../components/breadcrumb';
import { BackGround } from '../../components/background';
import { Link, useIntl, navigate } from "gatsby-plugin-react-intl"
import {postAPI} from "../../services/fetch";
import queryString from "query-string";
import {passwordRegist} from "../../services/auth";

const PasswordRegisterPage = ({ location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})

  const [password, setPassword] = React.useState({ latest: '', confirm: '' })
  const [errorPassword, setErrorPassword] = React.useState({isError: false, message: ''});
  const onClickUpdatePassword = async () => {
    setErrorPassword({isError: false, message: ''})

    if (password.latest === '' || password.confirm === '') {
      setErrorPassword({isError: true, message: t('users.register.error.pleasePassword')})
      return
    }
    if (password.latest !== password.confirm) {
      setErrorPassword({isError: true, message: t('users.register.error.differencePassword')})
      return
    }

    const { p } = queryString.parse(location.search)
    try {
      const response = await passwordRegist({
        p,
        password: password.latest,
        password_confirmation: password.confirm
      })
      if (response.data.result !== 0) {
        setErrorPassword({isError: true, message: t('users.register.error.invalidToken')})
        await navigate('/users/invalid')
        return
      }
      await navigate(`/users/nameregistration?p=${p}`)
    } catch (e) {
      setErrorPassword({isError: true, message: 'バリデーションエラー'})
    }
  }

  return (
    <Layout location={location}>
      <Seo title={t('users.register.seo.title')} description={t('users.register.seo.description')} />
      <BackGround>
        <section className={CommonStyles.wrapper}>
          <div className={CommonStyles.container}>
            <div id="users" className={Styles.users}>
              <h1>{t('users.register.title')}</h1>
              <section>
                <div>{t('users.register.message1')}<br/><br/></div>
                <div>
                  <div>
                    <label htmlFor="user_password">{t('users.register.password')}</label>
                    <input
                      id="user_password"
                      value={password.latest}
                      onChange={(e) => setPassword({...password, latest: e.target.value})}
                      autoFocus="autofocus"
                      type="password"
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label htmlFor="user_password_confirmation">{t('users.register.confirmPassword')}</label>
                    <input
                      id="user_password_confirmation"
                      value={password.confirm}
                      onChange={(e) => setPassword({...password, confirm: e.target.value})}
                      autoFocus="autofocus"
                      type="password"
                      autoComplete="off"
                    />
                  </div>
                  <div className={`${Styles.error_message} ${(errorPassword.isError) && Styles.is_error}`}>{errorPassword.message}</div>
                  <div className={Styles.text_center}>
                    <button
                      type="button"
                      className={Styles.btn_primary}
                      onClick={onClickUpdatePassword}
                    >
                      {t('users.register.registerPassword')}
                    </button>
                  </div>
                </div>
                <hr/>
                <div className={Styles.mg_t3}>
                  <div>{t('users.register.annotation.title')}</div>
                  <ul className={Styles.notice_list}>
                    <li>{t('users.register.annotation.line1')}</li>
                    <li>{t('users.register.annotation.line2')}</li>
                    <li>{t('users.register.annotation.line3')}</li>
                  </ul>
                </div>
              </section>
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export default PasswordRegisterPage;
